.ktor-options {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.ktor-options.flatten {
  position: absolute;
  top: -4px;
  right: -16px;
}
.ktor-options.flatten {
  .ktor-options__summary {
    display: none;
  }
  .ktor-options__promo {
    display: none;
  }
}
.ktor-options__content {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 16px;
  padding: 18px 24px;
}
.ktor-options__content label {
  padding: 4px 0;
}
.ktor-options__summary {
  display: flex;
  align-items: center;
  gap: 6px;
  font-variant-caps: small-caps;
}
.ktor-options__promo {
  margin-top: 12px;
  white-space: nowrap;
}
.ktor-options__footer {
  margin-top: 12px;
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
}

.ktor-options .engine {
  font-size: x-small;
  font-weight: bolder;
  position: relative;
  background-color: var(--ktor-background-dark);
  color: var(--ktor-background-color);
  line-height: 1;
  padding: 3px;
  border-radius: 3px;
}