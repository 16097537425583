.control-panel {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  z-index: 4;
  background-color: var(--ktor-background-color);
  min-height: var(--ktor-header-height);
  padding-top: 12px;
  transition: height 0.3s;
}
.control-panel__generate {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  gap: 48px;
}
.control-panel__generate.single-pane {
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .control-panel {
    display: flex;
    flex-direction: column;
  }
}

.control-panel__blurb {
  flex: 1 1 auto;
  overflow: hidden;
  transition: all 0.2s;
}
.control-panel__blurb > p {
  line-height: 1.4;
}

.control-panel__separator {
  border-left: 1px solid var(--ktor-borders-color);
  align-self: stretch;
}

.control-panel__form {
  display: flex;
  padding: 24px 36px !important; /* RescUI */
  gap: 36px;
  align-items: center;
  flex: none;
  min-height: 120px;
  transition: height 0.2s, gap 0.2s;
}

.control-panel__form.squeeze {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 24px !important;
}
.control-panel__form.squeeze.flatten {
  gap: 20px;
}
.control-panel__form.squeeze .control-panel__separator {
  display: none;
}
.control-panel__form.squeeze .control-panel__download {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
}
.control-panel__form.squeeze .control-panel__download-info {
  display: flex;
}
.control-panel__form.flatten .control-panel__download-file,
.control-panel__form.squeeze .control-panel__download-file {
  display: none;
}

.control-panel__details {
  display: grid;
  gap: 4px 0;
  grid-template-columns: 24px auto;
  grid-template-rows: 22px 22px 22px;
  line-height: 1;
  align-items: center;
  justify-items: start;
}
.control-panel__details svg {
  width: 16px;
  height: 16px;
}

.control-panel__details span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.control-panel__download {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.control-panel__download-info {
  /*display: none;*/
  position: relative;
  left: -12px;
}

.control-panel__tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  background-color: var(--ktor-background-color);
  gap: 16px;
}

.control-panel__plugins {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}
.control-panel__plugin-controls {
  display: flex;
  gap: 8px;
}
.control-panel__plugin-filters {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0 12px 0;
  gap: 12px;
}

.control-panel__plugin-search {
  flex: 1 1 auto;
  min-width: 200px;
}
.content-panel__plugin-select {
  flex: none;
  min-width: 180px;
}

@media screen and (max-width: 700px) {
  .content-panel__plugin-select {
    display: none;
  }
}