
.selected-plugins {
  display: flex;
  gap: 8px;
  align-self: flex-end;
  overflow-x: auto;
  padding: 6px 0;
}
.selected-plugins__plugin-tag {
  background-color: var(--ktor-primary-cold-background-color);
}
.selected-plugins__plugin {
  display: flex;
  gap: 8px;
  color: var(--ktor-text-color);
  line-height: 1;
  padding: 6px 6px 6px 10px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.selected-plugins__plugin-name {
  flex: 1 1 auto;
  padding-right: 16px;
}
.selected-plugins__plugin-name.highlight-for-removal {
  opacity: 0.6;
  text-decoration: line-through 3px #f26735;
}
.selected-plugins__plugin .close-icon {
  padding: 2px 2px 0 2px;
  border-radius: 2px;
}
.selected-plugins__plugin .close-icon:hover {

}
.selected-plugins__remove-list {
  margin-top: 4px;
  padding-left: 16px;
}