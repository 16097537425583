.preview-project-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 200px;
  position: relative;
}

.preview-project-panel__controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  gap: 12px;
}

.preview-project-panel__files {
  display: flex;
  flex: 1 1 auto;
  min-height: 200px;
}

ul.preview-project-panel__nav {
  border-right: 1px solid var(--ktor-borders-color);
  margin: 0;
  padding: 12px 0;
  width: 250px;
  flex: none;
}

.preview-project-panel__folder-nav {
  padding-left: 16px;
}
.preview-project-panel__file-preview {
  flex: 1 1 auto;
  overflow: auto;
}
.preview-project-panel__file-preview h3 {
  padding: 8px 16px;
  margin: 0;
  border-bottom: 1px solid var(--ktor-borders-color);
  background-color: rgba(128, 128, 128, 0.1);
}
.preview-project-panel__file-contents {
  flex: 1 1 auto;
  min-width: 250px;
  min-height: 100px;
  overflow-y: scroll;
}
.preview-project-panel__file-contents > div {
  min-height: 100%;
  background-color: transparent;
  --rs-typography-pre-bg-color: transparent;
}
.preview-project-panel__file-contents p {
  text-align: center;
  padding: 2em;
}

.preview-project-panel__refining-form {
  display: flex;
  border-radius: 24px;
  transition: width 0.3s;
  width: 108px;
  overflow: hidden;
}
.preview-project-panel__refining-form.expanded {
  border: 1px solid var(--ktor-primary-cold-background-color);
  width: 355px;
}
.preview-project-panel__refining-form label > div {
  border: none !important;
  background: none !important;
  padding: 0 12px;
}

.error-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);

  .error-overlay__wrapper {
    position: sticky;
    top: 75px;
    height: min(100%, 500px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-overlay__content {
    display: flex;
    align-items: center;
    gap: 18px;
    background-color: var(--ktor-background-color);
    padding: 24px;
    border-radius: 12px;
    max-width: 500px;
    max-height: 500px;
    overflow: auto;
    border: 1px solid var(--ktor-borders-color);
  }
}