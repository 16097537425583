.plugin-info {
  flex: 1 1 auto;
  overflow: auto;
  padding: 12px 16px;
}

@media screen and (max-width: 640px) {
  .plugin-info {
    display: none;
  }
  .copy-button {
    display: none;
  }
}

.plugin-info__header {
  display: flex;
  align-items: center;
  padding-bottom: 1em;
  gap: 1em;
  box-shadow: 0 0.35em rgba(0,0,0,0.1);
  margin-bottom: 2em;
}

.plugin-info__header-info {
  flex: 1 1 auto;
}

.plugin-info__header-info > h3 {
  margin: 0;
}

.plugin-info__vendor-details {
  display: flex;
  justify-content: flex-start;
  gap: 0.4em;
}


.plugin-info-markdown ul,
.plugin-info-markdown li,
.plugin-info-markdown p {
  font-size: 15px;
}

.plugin-info-markdown ul {
  padding-left: 19px;
}

.plugin-info-markdown h2,
.plugin-info-markdown h3,
.plugin-info-markdown h4 {
  margin-bottom: 5px;
  margin-top: 20px;
  color: #27282c;
}

.plugin-info__meta {
  display: flex;
  align-items: center;
  gap: 12px;
}
.plugin-info__documentation {
  padding: 0 16px 16px 16px;
}