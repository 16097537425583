.remove-feature-confirmation {
  background-color: var(--ktor-background-color);
}

.remove-feature-confirmation__content {
  padding: calc(2 * 8px) calc(2 * 8px) 0
    calc(2 * 8px);
  max-height: 350px;
  width: 350px;
  overflow: auto;
  font-size: var(--ring-font-size-smaller);
}

.remove-feature-confirmation__section {
  margin-bottom: calc(2 * 8px);
}

.remove-feature-confirmation__label {
  display: flex;
  align-items: flex-start;
}

.remove-feature-confirmation__label-text {
  margin-top: 2px;
}

.remove-feature-confirmation__list-item {
  margin: 6px -3px;
  --rs-color-primary-light-theme: var(--rs-color-dark);
}

.remove-feature-confirmation__footer {
  margin: 0;
  padding: 14px calc(2 * 8px) calc(2 * 8px)
    calc(2 * 8px);
  border-top: 1px solid var(--ktor-borders-color);
}
