.tutorial {
    margin-bottom: calc(4 * 8px);
}

.tutorial img {
    max-width: 70%;
    border: 1px solid var(--ktor-borders-color);
    box-shadow: var(--ktor-island-box-shadow);
}

.tutorial li {
    list-style: inside;
}

.tutorial li > p:first-child {
    display: inline;
}

code, pre {
    text-shadow: none !important;
}

pre .token {
    background: none !important;
}

th {
    border-bottom: 1px solid;
}
th, td {
    vertical-align: top;
    padding: 6px 12px;
}