#kt-gen1 {
  --ktor-primary-warm-background-color: #dd1265;
  --ktor-primary-cold-background-color: var(--rs-color-primary-light-theme);

  --ktor-secondary-warm-background-color: #f12278;
  --ktor-secondary-cold-background-color: #719dff;

  --ktor-pale-warm-background-color: #fef0eb;
  --ktor-pale-cold-background-color: #e0e3fe;
  --ktor-pale-neutral-background-color: #eeeeee;

  --ktor-island-box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.05);
  --ktor-borders-color: #d4d4d5;

  --ktor-text-color: var(--rs-typography-color-hard);
  --ktor-text-secondary: var(--rs-color-black-t20);
  --ktor-background-color: var(--rs-color-white);
  --ktor-background-dark: var(--rs-color-grey-90);
  --ktor-background-grey: var(--rs-color-grey-70);
  --ktor-shadow-color: var(--rs-color-black-t20);

  --rs-color-primary-light-theme-80: var(--ktor-secondary-warm-background-color);

  --ktor-header-height: 235px;

  background-color: var(--ktor-background-color);
  color: var(--ktor-text-color);
}

#kt-gen1.dark {
  --ktor-text-color: var(--rs-color-white-t90);
  --ktor-text-secondary: var(--rs-color-grey-40);
  --ktor-background-color: var(--rs-color-black);
  --ktor-borders-color: var(--rs-color-grey-80);
  --ktor-shadow-color: var(--rs-color-white-t10);

  --ktor-pale-warm-background-color: #343131;
  --ktor-pale-neutral-background-color: #323232;
  --ktor-pale-cold-background-color: #34353b;
  --ktor-background-dark: var(--rs-color-grey-20);
  --ktor-background-grey: var(--rs-color-grey-40);
  --rs-typography-color-average: var(--rs-color-grey-40);
  --rs-typography-color-pale: var(--rs-color-grey-50);
  --rs-typography-pre-color: #c7886f;
}

#kt-gen1.dark .rs-text-1_hardness_hard,
#kt-gen1.dark .rs-text-2_hardness_hard,
#kt-gen1.dark .rs-text-3_hardness_hard,
#kt-gen1.dark .rs-h1,
#kt-gen1.dark .rs-h2,
#kt-gen1.dark .rs-h3,
#kt-gen1.dark .rs-h4,
#kt-gen1.dark .rs-h5 {
  --rs-typography-color-hard: var(--rs-color-white-t90);
}

div[data-test="ring-popup"] {
  box-shadow: var(--ktor-island-box-shadow);
  border-radius: 0;
  border-color: var(--ktor-borders-color);
}

#kt-gen1 {
  height: 100vh;
  min-width: 350px;
  overflow-x: scroll;
}

#kt-gen1 > main {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.flex-gap {
  flex: 1 1 auto;
}


.ktor-page-header__wrapper {
  border-bottom: 1px solid var(--ktor-borders-color);
  padding: 8px 12px;
  margin-bottom: 8px;
}

.ktor-page-header {
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
}

.ktor-page-header__logo {
  color: var(--ktor-text-color);
  display: inline-grid;
  align-items: center;
  grid-template-columns: 48px 1fr;
  gap: 12px;
  letter-spacing: 1px;

  h1 {
    margin: 0;
    font-weight: 500;
  }
}

.ktor-page-header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ktor-page-header nav.vertical {
  flex-direction: column;
  align-items: stretch;
  width: 100px;
}
.ktor-page-header nav.vertical > a {
  background-color: transparent;
}
.ktor-page-header a {
  color: var(--ktor-text-color);
  text-decoration: none;
  cursor: pointer;
}
.ktor-page-header a.selected-page {
  text-decoration: underline;
  text-underline-color: var(--rs-underline-color);
  text-underline-offset: 12px;
}
.ktor-page-header nav > a {
  display: flex;
  align-items: center;
  padding: 6px 12px;
}
.ktor-page-header nav > a:hover {
  color: var(--ktor-primary-cold-background-color);
}

#kt-gen1.ktor-project-generator > main {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  #kt-gen1 > main {
    padding: 16px 8px;
  }
}