.final-step {
  padding: 24px;
  max-width: 800px;
}

.final-step__download-blurb {
  align-self: center;
  width: 500px;
  height: 280px;
  margin: 16px auto 48px auto;
}

.final-step__whats-next {
  padding: 0 calc(8px * 2);
}

.final-step__icon {
  margin-left: 8px;
  display: inline;
}

.final-step__marketplace-wrapper {
  position: relative;
  width: 384px;
}

.final-step__whats-next-content {
  opacity: 0;
  transform: translateY(36px);
}

.final-step__whats-next-content_visible {
  opacity: 1;
  transform: translateY(0px);
  transition:
    opacity ease 500ms,
    transform 500ms;
}

.final-step__whats-next-island-title {
  letter-spacing: -0.05em;
  margin-left: 6px;
  position: relative;
  top: -3px;
  font-size: 27px;
}

.final-step__whats-next-island-container {
  box-shadow: var(--ktor-island-box-shadow);
  max-width: 470px;
}

.final-step__teamcity-wrapper {
  margin-top: 19px;
}

.final-step__teamcity-wrapper form > *:first-child {
  margin: 0 calc(8px * 2) 7px 0;
  width: 300px;
}

.final-step__marketplace-install-widget {
  position: absolute;
  top: 208px;
  left: calc(8px * 3);
}

.final-step__toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 420px;
  justify-content: space-between;
  margin: 24px 0 88px 0;
}

.final-step__toolbar-item {
  margin-right: calc(3 * 8px);
}

.final-step__toolbar-item .final-step__icon {
  opacity: 0;
}

.final-step__toolbar-item:hover .final-step__icon {
  opacity: 1;
  transition: opacity 200ms ease;
  transition-delay: 500ms;
}
