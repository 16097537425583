#kt-gen1.plugin-preview {
  height: auto;
  min-height: 100vh;
  padding-bottom: 48px;
}
#kt-gen1.plugin-preview > main {
  display: block;
  max-width: min(95vw, 1024px);
  padding: 48px 0 0 0;
}
.plugin-preview > main > h1 {
  margin-top: 12px;
}
.plugin-preview__links {
  grid-area: links;
  display: inline-flex;
  gap: 12px;
}
.plugin-preview .plugin-info__documentation {
  margin-top: 16px;
}
.plugin-preview__generate-button {
  float: right;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0 -12px 24px 32px;
}
.plugin-preview__generate-button__header-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}
.plugin-preview__generate-button h3 {
  margin: 0;
}
.plugin-preview__generate-button p {
  margin-bottom: 24px;
}
.plugin-preview__ktor-logo {
  width: 24px;
  height: 24px;
}
.plugin-preview__create-link {
  align-self: flex-end;
}