.plugin-item {
  padding: 6px 10px;
  margin: 2px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border: solid 1px transparent;
  z-index: 2; /* needed for weird CSS in RescUI buttons */
  transition: all 0.2s;
}

.plugin-item:hover, .plugin-item:focus {
  background-color: var(--ktor-pale-cold-background-color);
}

.plugin-item.plugin-item_was-added {
  box-shadow: 4px 0 var(--ktor-primary-warm-background-color) inset;
  padding-left: 16px;
  --rs-color-dark: var(--ktor-primary-warm-background-color);
  --rs-color-dark-40: var(--ktor-primary-warm-background-color);
  --rs-color-dark-70: var(--ktor-secondary-warm-background-color);
}

.plugin-item.plugin-item_was-added:hover, .plugin-item.plugin-item_was-added:focus {
  background-color: var(--ktor-pale-warm-background-color);
}

.plugin-item.plugin-item_selected, .plugin-item:focus {
  position: sticky;
  top: var(--ktor-header-height);
  z-index: 3;
  border-color: var(--rs-color-dark-70);
  background-color: var(--ktor-pale-cold-background-color);
}

.plugin-item__text-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 8px 0 2px;
}

.plugin-item__name {
  font-weight: 600;
}

.plugin-item__description {
  line-height: var(--ring-line-height);
}

.plugin-item__button {
  visibility: hidden;
}

.plugin-item:hover .plugin-item__button,
.plugin-item:focus .plugin-item__button,
.plugin-item.plugin-item_selected .plugin-item__button,
.plugin-item.plugin-item_expanded .plugin-item__button,
.plugin-item__button_shown {
  visibility: visible;
}

.plugin-item__logo-missing {
  filter: opacity(75%);
  width: 32px;
}
