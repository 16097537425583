.plugins-list__list {
  position: relative;
}

.plugins-list__no-content-container {
  margin: 0 auto;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plugins-list__loading {
  padding-left: 8px;
}

.plugin-item-separator {
  display: flex;
  align-items: center;
  padding-left: 12px;
  background-color: var(--ktor-pale-neutral-background-color);
  border-bottom: 1px solid var(--ktor-borders-color);
  height: 24px;
  line-height: 24px;
  margin: 8px 0 4px 0;
}

.plugin-item-separator:first-child {
  margin-top: 4px;
}

.plugin-dropdown-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plugin-dropdown-option > span {
  opacity: 0.8;
}