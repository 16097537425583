.project-artifact-form {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 300px;
  gap: 16px;
}

.project-artifact-form__column-header {
  background-color: var(--rs-color-grey-40);
  color: var(--rs-color-black-t80);
  font-size: small;
  padding: 4px 8px;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
  min-width: 125px;
}

.project-artifact-form__row {
  display: flex;
  gap: 8px;
}

.project-artifact-form .form-trigger-contents {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-modal-contents-stack {
  display: flex;
  flex-direction: column;
}

.form-modal-contents {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 16px;
  color: var(--ktor-text-color);
}

.project-artifact-form .build-system-logo {
  width: 18px;
  margin: 0 8px 0 -8px;
}

.project-artifact-form .error-message {
  padding: 4px 12px 8px 12px;
  white-space: normal;
}